.Tilt {
    background: linear-gradient(90deg, rgba(85,0,124,1) 0%, rgba(121,9,98,1) 30%,rgb(96, 12, 132) 70%, rgb(111, 0, 255) 100%);
}

.Tilt-inner {
    filter: brightness(0.0);
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    transform: scaleY(-1);
    filter: invert(1);
}
