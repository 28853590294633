body {
  margin: 0;
  font-family: 'Courier New', Courier, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(90deg, rgba(85,0,124,1) 0%, rgba(121,9,98,1) 30%,rgb(96, 12, 132) 70%, rgb(111, 0, 255) 100%);

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.particles{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

button{ 
  cursor: pointer;
}
